body{
  background-image: url('assets/background.png');
  background-size: cover;
  background-attachment: fixed;
  background-position: 50%;
  min-width: auto;
  height: auto;
}

.App{
  display: flex;
  justify-content: center;
  margin-top: 73vh;
}

.App p{
  color: white;
  font-size: 3.5vh;
}